import React, { Component } from "react";
import { Container, Row, Col, Alert, ButtonGroup, Button } from "react-bootstrap";
import { GoX } from 'react-icons/go';
import Selector from "./selector/index";
import Workspace from "./workspace/index";
import Synchronizer from "./sync";
import Utils from "context/utils";
import Navigation from "context/nav";

export default class PlaygroundShell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      /**
       * State
       */

      // Global loading success status (manifest, credentials, etc)
      fatal: false,
      fatalMessage: null,
      // Definition operations progress flags
      progress: false,
      progressMessage: null,
      error: false,
      errorMessage: null,

      /**
       * Workflow
       */

      // All available workflow definitions
      workflowDefinitions: null,

      /**
       * Playground
       */

      // Playground manifest
      manifest: null,
      // All available playground definitions
      definitions: null,
      // Selected playground ID
      definitionId: null,
      // Selected playground definition item
      definition: null,
      // Initial state of currently selected definition object
      definitionObjectBackup: null,
      // Flag indicating whether definition is locally modified
      modified: false,

      /**
       * Session
       */

      // List of available sessions
      sessions: null,
      // Selected session ID
      sessionId: null,
      // Selected session
      session: null,
      // Session objects
      sessionObjects: null,
      // Selected session object data indexed by alias
      sessionData: {},
      // Selected session events
      sessionEvents: null,
      // Most recent creation time
      sessionEventCreatedAt: null,
      // Session sync
      sessionSync: false,
      // Number of sync cycles since last enablement
      sessionSyncCounter: 0,

      /**
       * Workspace
       */

      nodes: [],
      edges: [],

      /**
       * UX
       */

      library: {
        visible: false,
      },
      details: {
        visible: false,
        view: null,
        data: null
      },
      wideContent: false,

      /**
       * Interfaces
       */

      // ReactFlow control interface
      workflowInterface: {
        setNodes: this.setNodes,
        setEdges: this.setEdges
      },
      // Shell control interface
      shellInterface: {
        canEditRevision: this.canEditRevision,
        savePlayground: this.savePlayground,
        revertPlayground: this.revertPlayground,
        markModified: this.markModified,
        selectSession: this.selectSession,
        sessionSelected: this.sessionSelected,
        getState: this.getState,
        updateState: this.updateState,
        updatePane: this.updatePane,
        updateProgress: this.updateProgress,
        updateDetails: this.updateDetails,
        getApp: this.getApp,
        getRevision: this.getRevision,
        getSync: this.getSync,
        createWorkflowPath: this.createWorkflowPath,
        enableSessionSync: this.enableSessionSync
      },
    };

    this.sync = null;
  }

  /**
   * Shell interface
   */

  canEditRevision = () => {
    return this.props.app.api.canUpdateRevision(this.props.blob, this.props.revision);
  }

  savePlayground = () => {
    this.sync.savePlayground();
  }

  revertPlayground = () => {
    this.sync.revertPlayground();
  }

  markModified = () => {
    this.setState({ modified: true });
  }

  clearSessionState(session) {
    const promise = new Promise((resolve, reject) => {
      this.setState({
        sessionId: session["id"],
        session: session,
        sessionObjects: null,
        sessionData: {},
        sessionEvents: null,
        sessionEventCreatedAt: null,
      }, () => {
        resolve();
      });
    });
    return promise;
  }

  /**
   * Applies session selection based on URL upon initial page loading
   */
  selectSession = () => {
    // Skip if session is already selected
    if ( null != this.state.sessionId ) {
      return;
    }

    // Check the path
    const section = this.props.match.params.section;
    const sessionId = this.props.match.params.subSectionId;
    if ( "playground" != section || !sessionId ) {
      return
    }

    // Find session
    let requestedSession = null;
    for ( const session of this.state.sessions ) {
      if ( session["id"] == sessionId ) {
        requestedSession = session;
        break;
      }
    }
    if ( null == requestedSession ) {
      // Navigate to the root
      Navigation.push(this.props.history, this.createWorkflowPath("playground"));
      return;
    }

    // Select session
    this.sessionSelected(requestedSession);
  }

  sessionSelected = (session) => {
    // Update URL
    const sectionId = this.state.definition["alias"];
    const subSectionId = session["id"];
    Navigation.push(this.props.history, this.createWorkflowPath("playground", sectionId, subSectionId));

    // Begin the transition
    const promise = new Promise((resolve, reject) => {
      // Clear state before refreshing
      this.clearSessionState(session).then(() => {
        // Start synchronization
        this.sync.loadSessionContent();

        // Indicate completion
        resolve();
      });
    });
    return promise;
  }

  updateState = (updates) => {
    const promise = new Promise((resolve, reject) => {
      this.setState(updates, () => {
        resolve();
      });
    });
    return promise;
  }

  getState = () => {
    return this.state;
  }

  updateProgress = (progress, progressMessage, error, errorMessage) => {
    this.setState({
      progress: progress,
      progressMessage: progressMessage,
      error: error,
      errorMessage: errorMessage
    });
  }

  updatePane = (pane, visible) => {
    let paneObject = this.state[pane];
    paneObject.visible = visible;
    this.setState({pane: paneObject});
  }

  updateDetails = (view, data) => {
    let details = this.state.details;
    if ( details.view == view && details.data == data ) {
      return;
    }

    details.view = view;
    details.data = data;
    this.setState({
      details: details
    });
  }

  getRevision = () => {
    return this.props.revision;
  }

  getApp = () => {
    return this.props.app;
  }

  getSync = () => {
    return this.sync;
  }

  createWorkflowPath = (section, sectionId, subSectionId) => {
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;
    return Navigation.workflowPath(orgId, blobId, section, sectionId, subSectionId);
  }

  enableSessionSync = (enable) => {
    this.setState({
      sessionSync: enable,
      sessionSyncCounter: 0
    });
  }

  /**
   * Workflow interface
   */

  setNodes = (nodes, isModified) => {
    this.setState({
      nodes: nodes
    });

    if ( isModified ) {
      this.markModified();
    }
  }

  setEdges = (edges, isModified) => {
    this.setState({
      edges: edges
    });

    if ( isModified ) {
      this.markModified();
    }
  }

  /**
   * UX
   */

  componentDidMount() {
    this.sync = new Synchronizer(this.props.app, this.props.revision, this.state);
    this.sync.mounted = true;
    this.sync.loadPrerequisites();
    this.sync.autoRefreshSessionEvents();
  }

  componentWillUnmount() {
    this.sync.mounted = false;
    this.sync = null;
  }

  render() {
    if ( this.state.fatal ) {
      return (
        <>
          <Container className="blob-header-row content-row">
            <Alert variant="warning">
              {this.state.fatalMessage}
            </Alert>
          </Container>
        </>
      );
    }

    const selectorVisible
      = !this.state.progress
      && null != this.state.manifest
      && null != this.state.definitions
      && null != this.state.sessions;

    const workspaceVisible
      = null != this.state.manifest
      && null != this.state.definition
      && null != this.state.definitionObjectBackup;

    return (
      <>
        {this.state.progress &&
          <Container className="blob-header-row content-row">
            <Alert variant="primary">
              {this.state.progressMessage}
            </Alert>
          </Container>
        }

        {selectorVisible &&
          <Selector
            app={this.props.app}
            blob={this.props.blob}
            revision={this.props.revision}
            shell={this.state}
          />
        }

        {this.state.error &&
          <Container className="blob-header-row content-row">
            <Alert variant="warning">
              {this.state.errorMessage}
              <span className="float-right">
                <ButtonGroup size="sm" className="header-options">
                  <Button variant="light" className="btn-warning-close" onClick={(event) => {
                    this.updateProgress(false, null, false, null);
                  }}><GoX/></Button>
                </ButtonGroup>
              </span>
            </Alert>
          </Container>
        }

        {workspaceVisible &&
          <Workspace
            app={this.props.app}
            blob={this.props.blob}
            revision={this.props.revision}
            shell={this.state}
            shown={this.props.shown}
          />
        }
      </>
    );
  }
}
