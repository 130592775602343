import React, { Component } from 'react';
import { Card, Form, Badge, Button, ButtonGroup, Row, Col, Modal, Table } from 'react-bootstrap';
import { GoX } from "react-icons/go";
import AceEditor from "react-ace";
import Select from "react-select";
import Utils from "context/utils";

export default class EditMessageContentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portValue: null
    };
  }

  onEnter = () => {
    const portValue = Utils.deepCopy(this.props.portValue);
    this.setState({ portValue: portValue });
  }

  handleUpdate = () => {
    // Update message context
    this.props.portValue.message.content = this.state.portValue.message.content;
    this.props.shell.shellInterface.markModified();

    // Close modal
    this.props.onHide();
  }

  handleAddContentItem() {
    this.state.portValue.message.content.push({
      "type": "text",
      "text": ""
    });
    this.setState({ portValue: this.state.portValue });
  }

  handleRemoveContentItem(index) {
    this.state.portValue.message.content.splice(index, 1);
    this.setState({ portValue: this.state.portValue });
  }

  render() {
    const textOption = { label: "text", value: "text" };
    return (
      <Modal
        {...this.props}
        onEnter={this.onEnter}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Message Content
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(event) => {
            event.preventDefault();
            this.handleUpdate();
          }}>
            <Form.Group className="workflow-port-message-content-items-list">
              {this.state.portValue &&
                <Table hover bordered>
                  {this.state.portValue.message.content.map((contentItem, index) => {
                    return (
                      <tr>
                        <td>
                          <div>
                            <Form.Row className="align-items-center" style={{ "margin-bottom": "10px" }}>
                              <Col xs="auto">
                                Type
                              </Col>
                              <Col xs="auto">
                                <Select
                                  isDisabled={true}
                                  options={[textOption]}
                                  value={textOption}
                                  onChange={(option) => {}}
                                />
                              </Col>
                              <Col xs="auto">

                              </Col>
                              <Col>
                                <span className="float-right">
                                  {this.props.shell.shellInterface.canEditRevision() &&
                                    <Button variant="light" onClick={() => this.handleRemoveContentItem(index)}>
                                      <GoX />
                                    </Button>
                                  }
                                </span>
                              </Col>
                            </Form.Row>
                            <Form.Row className="align-items-center">
                              <Col>
                                <AceEditor
                                  name="message-content-item-editor"
                                  width="100%"
                                  mode="text"
                                  theme="monokai"
                                  wrapEnabled="true"
                                  readOnly={!this.props.shell.shellInterface.canEditRevision()}
                                  value={contentItem.text}
                                  minLines={3}
                                  maxLines={30}
                                  onChange={(newValue) => {
                                    contentItem.text = newValue;
                                  }}
                                />
                              </Col>
                            </Form.Row>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              }
            </Form.Group>

            {this.props.shell.shellInterface.canEditRevision() &&
              <Form.Group>
                <Button variant="success" onClick={() => this.handleAddContentItem()}>
                  Add
                </Button>
              </Form.Group>
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.onHide}>Close</Button>
          {this.props.shell.shellInterface.canEditRevision() &&
            <Button variant="success" onClick={this.handleUpdate}>Apply</Button>
          }
        </Modal.Footer>
      </Modal>
    );
  }
}
