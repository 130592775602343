import React, { Component } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { GoHistory, GoGear, GoWorkflow, GoPlay } from 'react-icons/go';
import { VscDebugLineByLine } from "react-icons/vsc";

export default class BlobTabs extends Component {
  handleTabSelect(tabSection) {
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;

    // Redirect to corresponding page
    var pagePath = `\/${orgId}\/${blobId}\/${tabSection}`;
    this.props.history.push(pagePath);
  }

  render() {
    // Handle parent default section selection
    let activeKey = "editor";
    if ( this.props.section && ( "default" != this.props.section ) ) {
      activeKey = this.props.section;
    }

    // Render tabs
    return (
      <>
        <Container className="blob-header-row main-tabs">
          <Tabs defaultActiveKey="editor" activeKey={activeKey} onSelect={(key) => this.handleTabSelect(key)}>
            <Tab eventKey="editor" title={<><GoWorkflow/> Editor</>}></Tab>
            <Tab eventKey="debugger" title={<><VscDebugLineByLine/> Debugger</>}></Tab>
            <Tab eventKey="playground" title={<><GoPlay/> Playground</>}></Tab>
            <Tab eventKey="revisions" title={<><GoHistory/> Revisions</>}></Tab>
            {(this.props.blob && this.props.app.api.isBlobSettingsAccessible(this.props.blob)) &&
              <Tab eventKey="settings" title={<><GoGear/> Settings</>}></Tab>
            }
          </Tabs>
        </Container>
      </>
    );
  }
}
