import React, { Component } from 'react';
import { Container, Row, Col, Alert, Button, ButtonGroup } from 'react-bootstrap';
import { GoX } from 'react-icons/go';
import Navigation from "context/nav";
import Selector from './selector/index'
import LibraryPane from "../controls/library/index.js"
import DetailsPane from './details/index';
import EditorPane from './editor';
import Converter from "./converter.js"

export default class EditorShell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Global loading success status (manifest, credentials, etc)
      fatal: false,
      fatalMessage: null,
      // Definition operations progress flags
      progress: false,
      progressMessage: null,
      error: false,
      errorMessage: null,
      // Workflow manifest object
      manifest: null,
      // The list of definition items
      definitions: null,
      // User credentials
      credentials: null,
      // Definition object corresponding to the currently selected definition item
      definition: null,
      // Flag indicating whether definition is locally modified
      modified: false,
      // Currently selected definition item
      current: {
        // Definition ID or alias
        reference: null,
        // Definition item
        item: null
      },
      // Pane configuration
      library: {
        visible: false,
      },
      details: {
        visible: false,
        view: null,
        data: null
      },
      // Appearance
      wideContent: false,
      // Nodes and edges rendered in ReactFlow control
      nodes: [],
      edges: [],
      // ReactFlow control interface
      workflowInterface: {
        setNodes: this.setNodes,
        setEdges: this.setEdges
      },
      // Global shell operations
      shellInterface: {
        canEditRevision: this.canEditRevision,
        toggleWideContent: this.toggleWideContent,
        toggleDetails: this.toggleDetails,
        updateDetails: this.updateDetails,
        markModified: this.markModified,
        saveWorkflow: this.saveWorkflow,
        revertWorkflow: this.revertWorkflow,
        checkWorkflow: this.checkWorkflow,
        switchDefinition: this.switchDefinition,
        reloadDefinitions: this.reloadDefinitions,
        updateProgress: this.updateProgress,
        updatePane: this.updatePane
      }
    };
  }

  componentDidMount() {
    this.loadWorkflowManifest();
    this.loadUserCredentials();
  }

  /**
   * Data loading
   */

  loadUserCredentials() {
    // Query the list of user's credentials
    this.props.app.api.usersIdCredentialsGet("me")
      .then(response => {
        // Update application state
        const credentials = response.data.credentials;
        this.setState({ credentials: credentials });
      }, error => {
        this.setState({
          fatal: true,
          fatalMessage: "Failed to load user credentials: " + error.message
        });
      });
  }

  loadWorkflowManifest() {
    // Indicate progress
    this.updateProgress(true, "Loading workflow manifest...", false, null);

    // Initiate manifest loading
    this.props.app.api.workflowManifestGet("workflow")
      .then( response => {
        // Extract manifest from the response body
        let manifest = response.data.manifest;

        // Post process manifest data structure
        let converter = new Converter(manifest);
        converter.parseManifest();

        // See if specific definition is desired
        let reference = null;
        const section = this.props.match.params.section;
        const sectionId = this.props.match.params.sectionId;
        if ( "editor" == section && sectionId ) {
          reference = sectionId;
        }

        // Update component state
        this.setState({
          // Notify the view that the manifest has been fetched
          manifest: manifest,
          // Also use this opportunity to request specific definition to be highlighted
          current: {
            reference: reference,
            item: null
          }
        });

        // Proceed to loading workflow definitions
        this.loadWorkflowDefinitions();
      }, error => {
        this.setState({
          fatal: true,
          fatalMessage: "Failed to load workflow manifest: " + error.message
        });
      });
  }

  loadWorkflowDefinitions() {
    // Indicate progress
    this.updateProgress(true, "Loading workflow definitions...", false, null);

    const body = {
      engine: "workflow_blobhub",
      command: "list_definitions",
      category: "workflow"
    };
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        const definitions = response.data.definitions;

        // Apply current selection
        let definition = null;
        if ( null != this.state.current.reference ) {
          // Find definition that is referenced
          for ( const item of definitions ) {
            if ( item["alias"] == this.state.current.reference || item["id"] == this.state.current.reference ) {
              definition = item;
              break;
            }
          }

          // Navigate to the root page if reference leads nowhere
          if ( null == definition ) {
            const orgId = this.props.match.params.orgId;
            const blobId = this.props.match.params.blobId;
            Navigation.push(this.props.history, Navigation.workflowPath(orgId, blobId, "editor"));
          }
        }

        // Default to the first definition
        if ( null == definition ) {
          if ( 0 != definitions.length ) {
            definition = definitions[0];
          }
        }

        // Remove progress
        this.updateProgress(false, null, false, null);

        // Notify the view that definitions have been fetched
        const current
          = ( null == definition ) ? {
            reference: null,
            item: null
          } : {
            reference: definition["id"],
            item: definition
          };
        this.setState({
          definitions: definitions,
          current: current
        }, () => {
          // Proceed to loading workflow definition
          this.loadWorkflowDefinition();
        });
      }, error => {
        // Render error state
        this.updateProgress(
          false, null, true, "Failed to load definitions: " + error.message);
      });
  }

  loadWorkflowDefinition() {
    if ( null == this.state.manifest || null == this.state.current.item ) {
      return;
    }

    // Indicate progress
    this.updateProgress(true, `Loading workflow definition "${this.state.current.item.alias}"...`, false, null);

    const body = {
      engine: "workflow_blobhub",
      command: "download_definition",
      definition_id: this.state.current.item["id"]
    };
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        const definition = response.data.definition_object;

        const converter = new Converter(this.state.manifest);
        const [initialNodes, initialEdges] = converter.blobhubDefinitionToReactFlow(
          definition
        );

        this.setState({
          definition: definition,
          nodes: initialNodes,
          edges: initialEdges,
          modified: false,
          details: {
            visible: false,
            view: null,
            data: null
          }
        });

        // Remove progress
        this.updateProgress(false, null, false, null);
      }, error => {
        // Render error state
        this.updateProgress(
          false, null, true, "Failed to download definition: " + error.message);
      });
  }

  /**
   * Workflow interface
   */

  setNodes = (nodes, isModified) => {
    this.setState({
      nodes: nodes
    });

    if ( isModified ) {
      this.markModified();
    }
  }

  setEdges = (edges, isModified) => {
    this.setState({
      edges: edges
    });

    if ( isModified ) {
      this.markModified();
    }
  }

  /**
   * Shell interface
   */

  canEditRevision = () => {
    return this.props.app.api.canUpdateRevision(this.props.blob, this.props.revision);
  }

  toggleWideContent = () => {
    this.setState({
      wideContent: !this.state.wideContent
    });
  }

  toggleDetails = () => {
    let details = this.state.details;
    details.visible = !details.visible;
    this.setState({
      details: details
    });
  }

  updateDetails = (view, data) => {
    let details = this.state.details;
    if ( details.view == view && details.data == data ) {
      return;
    }

    details.view = view;
    details.data = data;
    this.setState({
      details: details
    });
  }

  markModified = () => {
    this.setState({
      modified: true
    });
  }

  saveWorkflow = () => {
    // Display progress
    this.updateProgress(true, "Saving workflow definition...", false, null);

    // Convert ReactFlow representation into BlobHub workflow definition object
    const converter = new Converter(this.state.manifest);
    const definition = converter.reactFlowToBlobhubDefinition(this.state.nodes, this.state.edges);

    // Upload new definition
    const body = {
      engine: "workflow_blobhub",
      command: "upload_definition",
      definition_id: this.state.current.item.id,
      definition: definition
    };
    this.props.app.api.revisionsIdDataCommandPost(this.props.revision.id, body)
      .then( response => {
        // Remove progress
        this.updateProgress(false, null, false, null);

        // Update control state
        this.setState({
          definition: definition,
          modified: false
        });
      }, error => {
        // Render error state
        this.updateProgress(
          false, null, true, "Failed to save workflow definition: " + error.message);
      });
  }

  checkWorkflow = () => {
    // TODO:
  }

  revertWorkflow = () => {
    const converter = new Converter(this.state.manifest);
    const [initialNodes, initialEdges] = converter.blobhubDefinitionToReactFlow(
      this.state.definition
    );

    this.setState({
      nodes: initialNodes,
      edges: initialEdges,
      modified: false
    });
  }

  switchDefinition = (definition) => {
    // Navigate to definition path
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;
    const sectionId = definition["alias"];
    Navigation.push(this.props.history, Navigation.workflowPath(orgId, blobId, "editor", sectionId));

    // Notify the view that new definition is selected and is about to be loaded
    this.setState({
      current: {
        reference: definition["id"],
        item: definition
      },
      definition: null
    }, () => {
      // Load definition itself
      this.loadWorkflowDefinition();
    });
  }

  reloadDefinitions = (reference) => {
    // Clear current definition data keeping selected definition applied
    this.setState({
      current: {
        reference: reference,
        item: null
      },
      definition: null,
      definitions: null
    });

    // Reload the list
    this.loadWorkflowDefinitions();
  }

  updateProgress = (progress, progressMessage, error, errorMessage) => {
    this.setState({
      progress: progress,
      progressMessage: progressMessage,
      error: error,
      errorMessage: errorMessage
    });
  }

  updatePane = (pane, visible) => {
    let paneObject = this.state[pane];
    paneObject.visible = visible;
    this.setState({pane: paneObject});
  }

  /**
   * UX
   */

  render() {
    if ( this.state.fatal ) {
      return (
        <>
          <Container className="blob-header-row content-row">
            <Alert variant="warning">
              {this.state.fatalMessage}
            </Alert>
          </Container>
        </>
      );
    }

    const containerContentClass
      = this.state.wideContent || this.state.library.visible || this.state.details.visible
      ? "content-row-wide"
      : "content-row";

    return (
      <>
        {this.state.progress &&
          <Container className="blob-header-row content-row">
            <Alert variant="primary">
              {this.state.progressMessage}
            </Alert>
          </Container>
        }

        {(!this.state.progress && null != this.state.definitions ) &&
          <Selector
            app={this.props.app}
            blob={this.props.blob}
            revision={this.props.revision}
            shell={this.state}
          />
        }

        {this.state.error &&
          <Container className="blob-header-row content-row">
            <Alert variant="warning">
              {this.state.errorMessage}
              <span className="float-right">
                <ButtonGroup size="sm" className="header-options">
                  <Button variant="light" className="btn-warning-close" onClick={(event) => {
                    this.updateProgress(false, null, false, null);
                  }}><GoX/></Button>
                </ButtonGroup>
              </span>
            </Alert>
          </Container>
        }

        {null != this.state.definition &&
          <Container className={"blob-header-row " + containerContentClass}>
            <Row className={this.state.progress ? "make-readonly" : ""}>
              <Col sm={3} className={this.state.library.visible ? "" : "hidden"}>
                <LibraryPane shell={this.state} />
              </Col>
              <Col>
                <EditorPane
                  app={this.props.app}
                  blob={this.props.blob}
                  revision={this.props.revision}
                  shell={this.state}
                  shown={this.props.shown}
                />
              </Col>
              <Col sm={3} className={this.state.details.visible ? "" : "hidden"}>
                <DetailsPane
                  app={this.state}
                  blob={this.props.blob}
                  revision={this.props.revision}
                  shell={this.state}
                />
              </Col>
            </Row>
          </Container>
        }
      </>
    )
  }
}
