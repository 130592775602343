import React, { Component } from 'react';
import { Card, Form, Badge, Row, Col } from 'react-bootstrap';
import Creatable from "react-select/creatable";
import Select from "react-select";
import Converter from "../converter";

export default class DetailsPane extends Component {
  constructor(props) {
    super(props);
    this.basicTypes = new Set(["text", "integer", "float"]);
  }

  updatePortValueFromEvent(port, event) {
    const type = port["value"]["type"];
    let value = event.target.value;
    try {
      if ( "text" == type ) {
      } else if ( "integer" == type ) {
        const intValue = parseInt(value);
        value = ( `${intValue}` === value ) ? intValue : value;
      } else if ( "float" == type ) {
        const floatValue = parseFloat(value);
        value = ( `${floatValue}` === value ) ? floatValue : value;
      }
    } catch ( error ) {}
    if ( "text" == type ) {
      port["value"]["text"] = value;
    } else if ( "integer" == type ) {
      port["value"]["integer"] = value;
    } else if ( "float" == type ) {
      port["value"]["float"] = value;
    }
    this.props.shell.shellInterface.markModified();
  }

  renderPort(port, node) {
    const header = (
      <Card.Text>
        <Badge variant="secondary" className="title-tag-badge">{port.route}</Badge>
        &nbsp;&nbsp;<span className="text-muted">inline</span>
      </Card.Text>
    );

    const portKey = port["category"] + "-" + ( port["route"] || "default" );
    const portManifest = node.data.manifest["ports_index"][portKey];

    if ( this.basicTypes.has(port["value"]["type"]) ) {
      return (
        <>
          {header}
          <Card.Text>
            <Row className="align-items-center">
              {portManifest["value"]["range"] &&
                <Col sm={8}>
                  <Form.Control
                    type="range" size="sm"
                    disabled={!this.props.shell.shellInterface.canEditRevision()}
                    min={portManifest["value"]["range"]["from"]}
                    max={portManifest["value"]["range"]["to"]}
                    step={portManifest["value"]["range"]["step"]}
                    value={Converter.getPortValueData(port)}
                    onChange={(event) => {
                      this.updatePortValueFromEvent(port, event);
                    }}
                  />
                </Col>
              }
              <Col sm={portManifest["value"]["range"] ? 4 : 12}>
                <Form.Control
                  type="text" size="sm"
                  readOnly={!this.props.shell.shellInterface.canEditRevision()}
                  value={Converter.getPortValueData(port)}
                  onChange={(event) => {
                    this.updatePortValueFromEvent(port, event);
                  }}
                />
              </Col>
            </Row>
          </Card.Text>
        </>
      );
    }

    if ( "component_alias" == port["value"]["type"] ) {
      let options = [];
      let selectedOption = null;
      for ( const object of ( this.props.shell.sessionObjects || [] ) ) {
        const option = {
          value: object.alias,
          label: object.alias
        }
        options.push(option);
        if ( port["value"]["alias"] == object.alias ) {
          selectedOption = option;
        }
      }
      if ( null == selectedOption && port["value"]["alias"] ) {
        selectedOption = {
          value: port["value"]["alias"],
          label: port["value"]["alias"]
        };
      }

      return (
        <>
          {header}
          <Card.Text>
            <Creatable
              options={options}
              isDisabled={!this.props.shell.shellInterface.canEditRevision()}
              value={selectedOption}
              onChange={(option) => {
                port["value"]["alias"] = option.value;
                this.props.shell.shellInterface.markModified();
              }}
            />
          </Card.Text>
        </>
      );
    }

    if ( "workflow_definition_id" == port["value"]["type"] ) {
      let options = [];
      let selectedOption = null;
      for ( const workflowDefinition of ( this.props.shell.workflowDefinitions || [] ) ) {
        const option = {
          value: workflowDefinition["id"],
          label: workflowDefinition["alias"]
        }
        options.push(option);
        if ( port["value"]["id"] == workflowDefinition["id"] ) {
          selectedOption = option;
        }
      }
      if ( null == selectedOption && port["value"]["id"] ) {
        selectedOption = {
          value: port["value"]["id"],
          label: "no longer exists"
        };
      }

      return (
        <>
          {header}
          <Card.Text>
            <Select
              options={options}
              isDisabled={!this.props.shell.shellInterface.canEditRevision()}
              value={selectedOption}
              onChange={(option) => {
                port["value"]["id"] = option.value;
                this.props.shell.shellInterface.markModified();
              }}
            />
          </Card.Text>
        </>
      );
    }

    if ( "choice" == port["value"]["type"] ) {
      let options = [];
      let selectedOption = null;
      for ( const portValueOption of portManifest["value"]["options"] ) {
        const option = {
          value: portValueOption.id,
          label: portValueOption.label
        };
        options.push(option);
        if ( portValueOption.id == port.value.id ) {
          selectedOption = option;
        }
      }

      return (
        <>
          {header}
          <Card.Text>
            <Select
              options={options}
              value={selectedOption}
              isDisabled={!this.props.shell.shellInterface.canEditRevision()}
              onChange={(option) => {
                port["value"]["id"] = option.value;
                this.props.shell.shellInterface.markModified();
              }}
            />
          </Card.Text>
        </>
      );
    }

    return ( <></> );
  }

  renderPorts(label, ports, node) {
    return (
      <>
        <Card.Text as="h6">{label}</Card.Text>
        <hr />
        {ports.map((port, index) => {
          return this.renderPort(port, node);
        })}
      </>
    );
  }

  render() {
    const node = this.props.shell.details.data;

    return (
      <>
        <Card.Text as="h6">General</Card.Text>
        <hr />
        <Card.Text>Basics</Card.Text>
        <Card.Text>
          <table className="properties">
            <tr>
              <td className="prop-name-column">ID</td>
              <td className="prop-value-column"><code>{node.id}</code></td>
            </tr>
            <tr>
              <td className="prop-name-column">Type</td>
              <td className="prop-value-column"><code>{node.data.component["type"]}</code></td>
            </tr>
          </table>
        </Card.Text>
        <Card.Text>Name</Card.Text>
        <Card.Text>
          <Form.Control
            type="text" size="sm"
            readOnly={!this.props.shell.shellInterface.canEditRevision()}
            value={node.data.component["name"]}
            onChange={(event) => {
              node.data.component["name"] = event.target.value;
              this.props.shell.shellInterface.markModified();
            }}
          />
        </Card.Text>

        {this.renderPorts("Data Inputs", node.data.component["ports"], node)}
      </>
    );
  }
}
