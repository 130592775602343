import { Component } from "react";
import { Card, ButtonGroup, ToggleButton, Row, Col, Table, Alert, Badge } from "react-bootstrap";
import AceEditor from "react-ace";
import { marked, Renderer } from "marked";
import { GrDocument } from "react-icons/gr";
import { LuTableProperties, LuComponent } from "react-icons/lu";
import { CgDetailsMore } from "react-icons/cg";
import Tooltip from "../../../controls/common/tooltip";
import Clipboard from "../../../controls/common/clipboard";
import Utils from "context/utils";
import "./index.css";

class ContentItemText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewOption: this.props.contentView || "raw"
    };

    this.viewOptions = [
      { label: "Raw", value: "raw" },
      { label: "Markdown", value: "markdown" },
      { label: "JSON", value: "json" }
    ];
  }

  componentDidUpdate(prevProps) {
    if ( this.props.contentView != prevProps.contentView ) {
      this.setState({ viewOption: this.props.contentView });
    }
  }

  renderRaw() {
    return (
      <AceEditor
        name="playground-widget-message-content-item"
        width="100%"
        mode="text"
        theme="monokai"
        readOnly="true"
        wrapEnabled="true"
        value={this.props.contentItem.text}
        minLines={1}
        maxLines={30}
      />
    );
  }

  renderMarkdown() {
    var renderer = new Renderer();
    renderer.table = function(table) {
      let contents = marked.parse(table.raw);
      contents = contents.replace("<table>", '<table class="table table-hover">');
      return marked.parse(contents);
    };
    renderer.image = function(image) {
      return `<img src="${image.href}" alt="${image.text}" width="100%" />`;
    };
    var renderedMarkdown = {__html: marked.parse(this.props.contentItem.text, { renderer: renderer })};
    return (
      <span
        className="execution-events-details"
        dangerouslySetInnerHTML={renderedMarkdown}
      />
    );
  }

  renderJson() {
    let text;
    try {
      text = JSON.stringify(JSON.parse(this.props.contentItem.text), null, 2);
    } catch ( error ) {
      return (
        <Alert variant="warning">
          Content item contains invalid JSON.
        </Alert>
      );
    }

    return (
      <AceEditor
        name="playground-widget-message-content-item"
        width="100%"
        mode="json"
        theme="monokai"
        readOnly="true"
        wrapEnabled="true"
        value={text}
        minLines={3}
        maxLines={30}
      />
    );
  }

  render() {
    return (
      <div className="playground-message-content-item">
        <div className={"raw" == this.state.viewOption ? "" : "hidden"}>
          {this.renderRaw()}
        </div>
        <div className={"markdown" == this.state.viewOption ? "" : "hidden"}>
          {this.renderMarkdown()}
        </div>
        <div className={"json" == this.state.viewOption ? "" : "hidden"}>
          {this.renderJson()}
        </div>

        <span className="playground-message-content-item-controls">
          <ButtonGroup toggle size="sm">
            {this.viewOptions.map((option, index) => (
              <ToggleButton
                key={index}
                type="radio"
                variant="light"
                name="radio"
                value={option.value}
                checked={this.state.viewOption === option.value}
                onChange={(event) => { this.setState({ viewOption: event.currentTarget.value }) }}
              >
                {option.label}
              </ToggleButton>
            ))}
          </ButtonGroup>
          &nbsp;&nbsp;
          <Clipboard text={this.props.contentItem.text}/>
        </span>
      </div>
    );
  }
}

export default class Message extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewOption: "content"
    };

    this.viewOptions = [
      { label: <GrDocument/>, value: "content", tooltip: "Content" },
      { label: <LuTableProperties/>, value: "attributes", tooltip: "Attributes" },
      { label: <LuComponent/>, value: "providers", tooltip: "Providers" },
      { label: <CgDetailsMore/>, value: "metadata", tooltip: "Metadata" },
    ];
  }

  renderContent() {
    if ( !this.props.value.message.content ) {
      return (
        <Alert variant="info">
          Message content is not specified.
        </Alert>
      );
    }

    return (
      <Table bordered hover className="playground-message-content">
        <tbody>
          {this.props.value.message.content.map((contentItem, index) => (
            <tr>
              <td>
                {"text" == contentItem.type &&
                  <ContentItemText
                    contentItem={contentItem}
                    contentView={this.props.contentView}
                  />
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  renderMessageField(field) {
    if ( !this.props.value.message[field] ) {
      return (
        <Alert variant="info">
          Message "{field}" field is not specified.
        </Alert>
      );
    }
    return (
      <>
        <Card.Text>
          <AceEditor
            name="playground-widget-message-attributes"
            width="100%"
            mode="json"
            theme="monokai"
            readOnly="true"
            wrapEnabled="true"
            value={JSON.stringify(this.props.value.message[field], null, 2)}
            minLines={3}
            maxLines={30}
          />
        </Card.Text>
      </>
    );
  }

  render() {
    if ( !this.props.value ) {
      return (
        <Card.Text>
          <Alert variant="warning">
            Message is not provided.
          </Alert>
        </Card.Text>
      );
    }
    const message = this.props.value["message"];
    if ( !message ) {
      return (
        <Card.Text>
          <Alert variant="warning">
            Message contains no value.
          </Alert>
        </Card.Text>
      );
    }

    return (
      <div>
        <Row style={{"margin-bottom": "12px"}}>
          <Col sm={"auto"} className="d-flex align-items-center">
            <Badge variant="secondary" className="title-tag-badge">{message["role"]}</Badge>
            {(message["attributes"] && message["attributes"]["created_at"]) &&
              <>
                &nbsp;&nbsp;
                <span className="text-muted" style={{"font-size": "14px"}}>
                  {Utils.formatDateTime(message["attributes"]["created_at"])}
                </span>
              </>
            }
          </Col>
          <Col>
            <span className="float-right">
              <ButtonGroup toggle size="sm">
                {this.viewOptions.map((option, index) => (
                  <Tooltip text={option.tooltip}>
                    <ToggleButton
                      key={index}
                      type="radio"
                      variant="light"
                      name="radio"
                      value={option.value}
                      checked={this.state.viewOption === option.value}
                      onChange={(event) => { this.setState({ viewOption: event.currentTarget.value }) }}
                    >
                      {option.label}
                    </ToggleButton>
                  </Tooltip>
                ))}
              </ButtonGroup>
            </span>
          </Col>
        </Row>

        <div className={"content" == this.state.viewOption ? "" : "hidden"}>
          {this.renderContent()}
        </div>
        <div className={"attributes" == this.state.viewOption ? "" : "hidden"}>
          <Table bordered hover className="playground-message-content">
            <tbody><tr><td>
              {this.renderMessageField("attributes")}
            </td></tr></tbody>
          </Table>
        </div>
        <div className={"providers" == this.state.viewOption ? "" : "hidden"}>
          <Table bordered hover className="playground-message-content">
            <tbody><tr><td>
              {this.renderMessageField("providers")}
            </td></tr></tbody>
          </Table>
        </div>
        <div className={"metadata" == this.state.viewOption ? "" : "hidden"}>
          <Table bordered hover className="playground-message-content">
            <tbody><tr><td>
              {this.renderMessageField("metadata")}
            </td></tr></tbody>
          </Table>
        </div>
      </div>
    );
  }
}
