import { Component } from "react";
import { Card, Row, Col, Badge, Button } from "react-bootstrap";
import { GoSync } from "react-icons/go";
import Clipboard from "../../controls/common/clipboard";

export default class DetailsPane extends Component {
  render() {
    return (
      <>
        {this.props.shell.session &&
          <>
            <Card.Text as="h6">Session Objects</Card.Text>
            <hr />
            <Card.Text>
              {null != this.props.shell.sessionObjects ?
                <>
                  {0 != this.props.shell.sessionObjects.length ?
                    <>
                      {this.props.shell.sessionObjects.map((object, index) => {
                        const data = this.props.shell.sessionData[object.alias];

                        return (
                          <Row style={{"height": "33px"}}>
                            <Col className="d-flex align-items-center">
                              <Badge variant="secondary" className="title-tag-badge">{object.alias}</Badge>
                              &nbsp;&nbsp;
                              <span className="text-muted">alias</span>
                            </Col>
                            <Col sm={"auto"}  className="d-flex align-items-center">
                              {data &&
                                <>
                                  <span className="text-muted">value</span>
                                  &nbsp;&nbsp;
                                  <Clipboard text={JSON.stringify(data, null, 2)} />
                                </>
                              }
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  : <>No objects are available within the selected session.</> }
                </>
              : <>Loading session objects...</> }
            </Card.Text>

            {this.props.shell.shellInterface.canEditRevision() &&
              <>
                <Card.Text as="h6">Actions</Card.Text>
                <hr />
                <Card.Text style={{"margin-top": "20px"}}>
                  <Button variant="light" size="sm" onClick={() => {
                    this.props.shell.shellInterface.getSync().loadSessionContent();
                  }}><GoSync />&nbsp;&nbsp;Refresh Session Objects</Button>
                </Card.Text>
                <Card.Text>
                  Pulls the list of objects persisted within the session and loads its contents.
                </Card.Text>
              </>
            }
          </>
        }

        {!this.props.shell.session &&
          <Card.Text>
            Select session or widget to see its properties displayed here.
          </Card.Text>
        }
      </>
    );
  }
}
