import {
  ReactFlow,
  Controls,
  Background,
  BackgroundVariant,
  applyNodeChanges,
  applyEdgeChanges,
  useReactFlow,
  addEdge
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import Converter from "./converter.js"
import Connections from "./connections.js"
import ProcessorNode from "./processorNode.js"
import "./nodes.css";

const FlowWrapper = ({shell, shown, ...rest}) => {
  const converter = new Converter(shell.manifest);

  const nodeTypes = {
    processorNode: ProcessorNode,
  };

  const onNodesChange = (changes) => {
    // Mark workflow as modified if impacted
    const isModified = converter.doReactFlowChangesImpactDefinition(changes);

    // Ignore changes occurring
    // - revision is not editable
    // - while the component is hidden
    if ( isModified && ( !shell.shellInterface.canEditRevision() || !shown ) ) {
      return;
    }

    // Apply changes
    shell.workflowInterface.setNodes(applyNodeChanges(changes, shell.nodes), isModified);
  };

  const onEdgesChange = (changes) => {
    // Mark workflow as modified if impacted
    const isModified = converter.doReactFlowChangesImpactDefinition(changes);

    // Ignore changes occurring
    // - revision is not editable
    // - while the component is hidden
    if ( isModified && ( !shell.shellInterface.canEditRevision() || !shown ) ) {
      return;
    }

    // Apply changes
    shell.workflowInterface.setEdges(applyEdgeChanges(changes, shell.edges), isModified);
  };

  const onConnect = (connection) => {
    // Ignore changes occurring
    // - revision is not editable
    if ( !shell.shellInterface.canEditRevision() ) {
      return;
    }

    // Confirm compatibility of connection ports
    if ( !Connections.checkConnectionPortsCompatibility(connection) ) {
      return;
    }

    // Workflow/processor limits are not enforced in here to enable free-form flow editing with minimal restrictions.
    // All constraints are verified and enforced on the backend in the routine preceding workflow execution.

    // Prepare edge object
    converter.completeNewEdgeDefinition(shell.nodes, shell.edges, connection);

    // Create edge based on connection
    shell.workflowInterface.setEdges(addEdge(connection, shell.edges), true);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const { screenToFlowPosition } = useReactFlow();

  const onDrop = (event) => {
    let processorDefinition = {};

    try {
      // Retrieve processor definition from the event
      processorDefinition = JSON.parse(event.dataTransfer.getData("text/plain"));
    } catch {
      return;
    }

    // Prevent default action
    event.preventDefault();

    // Determine position of the dropped element
    const position = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    // Create new node
    const node = converter.createNewNode(processorDefinition, position, shell.nodes);

    // Add node to the global list
    shell.workflowInterface.setNodes(shell.nodes.concat(node), true);
  };

  const onSelectionChange = (params) => {
    if ( 1 == params.nodes.length && 0 == params.edges.length ) {
      shell.shellInterface.updateDetails("processor", params.nodes[0]);
    } else if ( 0 == params.nodes.length && 1 == params.edges.length ) {
      shell.shellInterface.updateDetails("connection", params.edges[0]);
    } else {
      shell.shellInterface.updateDetails(null, null);
    }
  }

  return (
    <ReactFlow
      nodes={shell.nodes}
      edges={shell.edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onSelectionChange={onSelectionChange}
      nodeTypes={nodeTypes}
      fitView
      attributionPosition="bottom-right"
      {...rest}
    >
      {!shell.progress &&
        <Controls
          showInteractive={false}
        />
      }
      <Background id="workflow-background" variant={BackgroundVariant.Dots} gap={12} size={1} />
    </ReactFlow>
  )
}

export default FlowWrapper;
