import React, { Component } from "react";
import { Alert, Badge } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import { PiWarningBold } from "react-icons/pi";
import { LuText } from "react-icons/lu";
import { MdOutlineEventAvailable } from "react-icons/md";
import Utils from 'context/utils';
import Components from "../../controls/common/components";
import "./event.css";

class EventBody extends Component {
  render() {
    return (
      <table>
        <tr>
          <td className="execution-event-item-icon-cell">
            {this.props.icon}
          </td>
          {this.props.subIcon &&
            <td className="execution-event-item-sub-icon-cell">
              {this.props.subIcon}
            </td>
          }
          <td>
            {this.props.children}
          </td>
        </tr>
      </table>
    );
  }
}

export default class Event extends Component {
  constructor(props) {
    super(props);
    this.components = new Components();
  }

  /**
   * Helpers
   */

  renderEventTypeRow() {
    return (
      <div className="execution-event-item-event-type-row">
        <span>
          <Badge variant="light" style={{"font-weight": "580"}}>
            <MdOutlineEventAvailable />
            &nbsp;&nbsp;
            {this.props.event["type"]}
          </Badge>
        </span>
        <span className="float-right text-muted">
          {Utils.formatTime(this.props.event["created_at"])}
        </span>
      </div>
    );
  }

  renderEventMessageRow(message) {
    return (
      <>
        {message &&
          <div className="execution-event-item-event-details-row">
            <Badge variant="light" style={{"font-weight": "normal"}}>
              {this.trimMessageLength(message)}
            </Badge>
          </div>
        }
      </>
    );
  }

  renderEventStatusRow(message) {
    return (
      <>
        <div className="execution-event-item-event-details-row">
          <Badge variant="secondary" className="title-tag-badge">
            Route: <strong>{this.props.event["attributes"]["route"]}</strong>
          </Badge>
          {this.props.event["attributes"]["runtime"] &&
            <>
              &nbsp;&nbsp;
              <Badge variant="secondary" className="title-tag-badge">
                Duration: <strong>{(this.props.event["attributes"]["runtime"] / 1000000.0).toFixed(2)}s</strong>
              </Badge>
            </>
          }
        </div>
      </>
    );
  }

  trimMessageLength(message) {
    const maxLength = 100;
    if ( message.length > maxLength ) {
      return message.substr(0, maxLength) + "...";
    } else {
      return message;
    }
  }

  /**
   * Event Types
   */

  renderFlowEnteredEvent() {
    return (
      <EventBody
        icon={this.components.getComponentIcon("flow.entered")}
      >
        {this.renderEventTypeRow()}
        {this.renderEventMessageRow("Starting workflow execution...")}
      </EventBody>
    );
  }

  renderFlowExitedEvent() {
    return (
      <EventBody
        icon={this.components.getComponentIcon("flow.exited")}
      >
        {this.renderEventTypeRow()}
        {this.renderEventStatusRow()}
      </EventBody>
    );
  }

  renderProcessorEnteredEvent() {
    return (
      <EventBody
        icon={this.components.getComponentIcon(this.props.event["attributes"]["processor_type"])}
      >
        {this.renderEventTypeRow()}
        <div className="execution-event-item-event-details-row">
          <Badge variant="light" style={{"font-weight": "550"}}>
            {this.props.event["attributes"]["processor_type"]}
          </Badge>
          &nbsp;&nbsp;
          <strong style={{"font-weight": "750"}}>
            {this.props.event["attributes"]["processor_name"]}
          </strong>
        </div>
      </EventBody>
    );
  }

  renderProcessorExitedEvent() {
    return (
      <EventBody
        subIcon={"failure" == this.props.event["attributes"]["route"] ? <PiWarningBold /> : <FaCheck />}
      >
        {this.renderEventTypeRow()}
        {this.renderEventStatusRow()}
      </EventBody>
    );
  }

  renderDefaultEvent() {
    let subIcon = null;
    if ( "processor.error" == this.props.event["type"] || "flow.error" == this.props.event["type"] ) {
      subIcon = <PiWarningBold />;
    } else if ( "processor.output" == this.props.event["type"] ) {
      subIcon = <LuText />;
    }

    return (
      <EventBody
        subIcon={subIcon}
      >
        {this.renderEventTypeRow()}
        {this.renderEventMessageRow(this.props.event["message"])}
      </EventBody>
    );
  }

  render() {
    const eventType = this.props.event["type"];
    if ( "flow.entered" == eventType ) {
      return this.renderFlowEnteredEvent();
    } else if ( "flow.exited" == eventType ) {
      return this.renderFlowExitedEvent();
    } else if ( "processor.entered" == eventType ) {
      return this.renderProcessorEnteredEvent();
    } else if ( "processor.exited" == eventType ) {
      return this.renderProcessorExitedEvent();
    } else { // "flow.info", "flow.error", "processor.output", "processor.error"
      return this.renderDefaultEvent();
    }
  }
}
