import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { GoTrash, GoPencil, GoWorkflow, GoLock } from 'react-icons/go';
import Select from "react-select";
import Styles from "../../controls/common/styles";
import "./index.css";

class AddDefinitionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      definition: {
        id: null,
        alias: ""
      }
    };

    this.form = React.createRef();
  }

  onEnter = () => {
    if ( this.props.definition ) {
      this.setState({
        definition: {
          id: this.props.definition.id,
          alias: this.props.definition.alias
        }
      });
    } else {
      this.setState({
        definition: {
          id: null,
          alias: ""
        }
      });
    }
  }

  handleAddDefinition = () => {
    // Check form validity
    if ( !this.form.current.reportValidity() ) {
      return;
    }

    // Initiate processing
    if ( this.props.definition ) {
      this.props.handleEditDefinition(this.state.definition);
    } else {
      this.props.handleAddDefinition(this.state.definition);
    }

    // Close modal
    this.props.onHide();
  }

  render() {
    return (
      <Modal
        {...this.props}
        onEnter={this.onEnter}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.definition
              ? <>Edit Workflow Definition</>
              : <>Add New Workflow Definition</>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={this.form} onSubmit={(event) => {
            event.preventDefault();
            this.handleAddDefinition();
          }}>
            <Form.Group>
              {this.props.definition &&
                <>
                  <Form.Text>
                    <strong>Workflow Definition ID</strong>
                  </Form.Text>
                  <Form.Text>
                    <code>{this.props.definition.id}</code>
                  </Form.Text>
                  <hr />
                </>
              }
              <Form.Label>
                <strong>Alias</strong> <span className="text-muted">(required)</span>
              </Form.Label>
              <Row>
                <Col sm={3}>
                  <Form.Control
                    required
                    type="text"
                    size="sm"
                    autoFocus
                    value={this.state.definition.alias}
                    onChange={(event) => {
                      let definition = this.state.definition;
                      definition.alias = event.target.value;
                      this.setState({definition: definition});
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                Workflow alias must confirm to the following requirements:
                <ul>
                  <li>minimum of 6 characters;</li>
                  <li>maximum of 32 characters;</li>
                  <li>only alphanumeric characters along with <strong>-</strong>,<strong>_</strong> are allowed;</li>
                  <li>must be unique within the scope of the revision.</li>
                </ul>
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.onHide}>Close</Button>
          <Button variant="success" onClick={this.handleAddDefinition}>
            {this.props.definition ? "Apply" : "Add"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class DeleteDefinitionModal extends Component {
  handleDeleteDefinition = () => {
    // Initiate processing
    this.props.handleDeleteDefinition();

    // Close modal
    this.props.onHide();
  }

  render() {
    if ( !this.props.definition ) {
      return ( <></> );
    }

    return (
      <Modal
        {...this.props}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Workflow Definition
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure that you would like to delete definition <strong>{this.props.definition.alias}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.onHide}>No</Button>
          <Button variant="success" onClick={this.handleDeleteDefinition}>Yes</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default class SelectorRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addDefinitionModalShow: false,
      definitionToBeEdited: null,
      deleteDefinitionModalShow: false
    };
  }

  /**
   * Add new
   */

  setAddDefinitionModalShow(show) {
    this.setState({
      addDefinitionModalShow: show,
      definitionToBeEdited: null
    });
  }

  handleAddDefinition(definition) {
    this.props.shell.shellInterface.updateProgress(true, "Creating new workflow definition...", false, null);

    const body = {
      engine: "workflow_blobhub",
      command: "create_definition",
      category: "workflow",
      alias: definition.alias
    };
    this.props.app.api.revisionsIdDataCommandPost(this.props.revision.id, body)
      .then( response => {
        // Remove progress
        this.props.shell.shellInterface.updateProgress(false, null, false, null);

        // Re-load definitions
        this.props.shell.shellInterface.reloadDefinitions(definition.id);
      }, error => {
        // Render error state
        this.props.shell.shellInterface.updateProgress(
          false, null, true, "Failed to create workflow definition: " + error.message);
      });
  }

  /**
   * Edit
   */

  setEditDefinitionModalShow(show) {
    this.setState({
      addDefinitionModalShow: show,
      definitionToBeEdited: show ? this.props.shell.current.item : null
    });
  }

  handleEditDefinition(definition) {
    this.props.shell.shellInterface.updateProgress(true, "Applying workflow definition changes...", false, null);

    const body = {
      engine: "workflow_blobhub",
      command: "update_definition",
      definition_id: definition.id,
      alias: definition.alias
    };
    this.props.app.api.revisionsIdDataCommandPost(this.props.revision.id, body)
      .then( response => {
        // Remove progress
        this.props.shell.shellInterface.updateProgress(false, null, false, null);

        // Re-load definitions
        this.props.shell.shellInterface.reloadDefinitions(definition.alias);
      }, error => {
        // Render error state
        this.props.shell.shellInterface.updateProgress(
          false, null, true, "Failed to update workflow definition: " + error.message);
      });
  }

  /**
   * Delete
   */

  setDeleteDefinitionModalShow(show) {
    this.setState({deleteDefinitionModalShow: show});
  }

  handleDeleteDefinition() {
    this.props.shell.shellInterface.updateProgress(true, "Deleting workflow definition...", false, null);

    const definition = this.props.shell.current.item;
    const body = {
      engine: "workflow_blobhub",
      command: "delete_definition",
      definition_id: definition["id"]
    };
    this.props.app.api.revisionsIdDataCommandPost(this.props.revision.id, body)
      .then( response => {
        // Remove progress
        this.props.shell.shellInterface.updateProgress(false, null, false, null);

        // Re-load definitions
        this.props.shell.shellInterface.reloadDefinitions(null);
      }, error => {
        // Render error state
        this.props.shell.shellInterface.updateProgress(
          false, null, true, "Failed to delete workflow definition: " + error.message);
      });
  }

  /**
   * Rendering
   */

  render() {
    let reference = this.props.shell.current.reference;
    let definitionOptions = [];
    let currentOption = null;
    for ( const definition of this.props.shell.definitions ) {
      const definitionOption = {
        value: definition,
        label: definition.alias
      };
      if ( definition.alias == reference || definition.id == reference ) {
        currentOption = definitionOption;
      }
      definitionOptions.push(definitionOption);
    }

    // Disable all actions until changes are committed or if another action is already in progress.
    const disabled = this.props.shell.progress || this.props.shell.modified;

    // This one is used to hide controls in parallel with `disabled`. They never interfere.
    const canEdit = this.props.shell.shellInterface.canEditRevision();

    return (
      <>
        <Container className="blob-header-row content-row workflow-selector-container">
          <div className="blob-download-row workflow-selector-row">
            {0 != this.props.shell.definitions.length &&
              <>
                <span disabled={disabled}>
                  Workflow&nbsp;&nbsp;&nbsp;
                </span>
                <span>
                  <div className="workflow-selector-dropdown">
                    <Select
                      isDisabled={disabled}
                      options={definitionOptions}
                      value={currentOption}
                      onChange={(option) => {
                        this.props.shell.shellInterface.switchDefinition(option.value);
                      }}
                      menuPortalTarget={document.body}
                      styles={Styles.headerSelect()}
                    />
                  </div>
                </span>
                {canEdit &&
                  <span className="align-top">
                    &nbsp;&nbsp;&nbsp;
                    <Button disabled={disabled} variant="light" size="sm"
                      onClick={() => this.setEditDefinitionModalShow(true)}>
                      <GoPencil /> Edit
                    </Button>
                    &nbsp;&nbsp;
                    <Button disabled={disabled} variant="light" size="sm"
                      onClick={() => this.setDeleteDefinitionModalShow(true)}>
                      <GoTrash /> Delete
                    </Button>
                  </span>
                }
              </>
            }
            {0 == this.props.shell.definitions.length &&
              <span>
                Create the first workflow definition here.
              </span>
            }

            <span className="float-right">
              {disabled &&
                <>
                  <div className="workflow-selector-locked-icon align-top">
                    <GoLock />&nbsp;
                  </div>
                  <div className="workflow-selector-locked-notice text-muted">
                    Save or revert changes to unlock switching workflows.
                  </div>
                </>
              }
              {canEdit &&
                <Button disabled={disabled} variant="success" size="sm" className="align-top"
                  onClick={() => this.setAddDefinitionModalShow(true)}>
                  <GoWorkflow /> New
                </Button>
              }
            </span>
          </div>
        </Container>

        <AddDefinitionModal
          show={this.state.addDefinitionModalShow}
          onHide={() => this.setAddDefinitionModalShow(false)}
          handleAddDefinition={(definition) => this.handleAddDefinition(definition)}
          handleEditDefinition={(definition) => this.handleEditDefinition(definition)}
          definition={this.state.definitionToBeEdited}
        />

        <DeleteDefinitionModal
          show={this.state.deleteDefinitionModalShow}
          onHide={() => this.setDeleteDefinitionModalShow(false)}
          handleDeleteDefinition={() => this.handleDeleteDefinition()}
          definition={this.props.shell.current.item}
        />
      </>
    );
  }
}

