import axios from 'axios';
import Config from 'context/config';

export default class Api {

  // TODO: Rename all methods to match naming convention.

  constructor(state, setAppState, getAppState) {
    this.setAppState = setAppState;
    this.getAppState = getAppState;

    // Currently supported API version
    this.api_version = "v1";

    // Default client for basic operations
    this.axios = axios.create({
      baseURL: Config.apiBaseUrl()
    });

    // Default client for basic authenticated operations
    this.isAuthenticating = false;
    this.authSubscribers = [];
    this.axiosAuth = axios.create({
      baseURL: Config.apiBaseUrl()
    });
    this.axiosAuth.interceptors.request.use((config) => { return this.auth_header_interceptor(config) }, null);

    // Client capable of refreshing token in response to auth errors
    this.isRefreshing = false;
    this.refreshSubscribers = [];
    this.axiosRefresh = axios.create({
      baseURL: Config.apiBaseUrl()
    });
    this.axiosRefresh.interceptors.request.use((config) => { return this.auth_header_interceptor(config) }, null);
    this.axiosRefresh.interceptors.response.use(null, (error) => { return this.auth_error_interceptor(error) });

    // Users cache
    this.usersCacheById = {};

    // Load locally cached authentication state
    this.loadLocalAuthState(state);
  }

  log(...args) {
    if ( Config.debug ) {
      console.log(...args);
    }
  }

  /**
   * Public state controls
   */

  loadLocalAuthState(state) {
    try {
      // Attempt to load creds from local storage
      const localAuth = localStorage.getItem("auth");
      const auth = JSON.parse(localAuth);
      if ( auth ) {
        state.authenticated = auth != null;
        state.auth = auth;
      } else {
        throw Error("no_creds_found");
      }
    } catch (err) {
      // Authentication is delayed until API interaction is requested
    }
  }

  signout() {
    // Clear authentication data
    this.unAuthenticate();
  }

  /**
   * State management
   */

  authenticate(anonymous, refreshToken, accessToken, expiresAt) {
    const promise = new Promise((resolve, reject) => {
      // Prepare auth state
      const auth = {
        anonymous: anonymous,
        refreshToken: refreshToken,
        accessToken: accessToken,
        expiresAt: expiresAt
      };

      // Persist globally
      localStorage.setItem("auth", JSON.stringify(auth));

      // Update locally
      this.setAppState({
        authenticated: true,
        auth: auth
      }, () => {
        resolve();
      });
    });
    return promise;
  }

  refreshToken(accessToken, expiresAt) {
    const promise = new Promise((resolve, reject) => {
      // Prepare auth state
      const auth = {
        anonymous: this.getAppState().auth.anonymous,
        refreshToken: this.getAppState().auth.refreshToken,
        accessToken: accessToken,
        expiresAt: expiresAt
      };

      // Persist globally
      localStorage.setItem("auth", JSON.stringify(auth));

      // Update locally
      this.setAppState({
        authenticated: true,
        auth: auth
      }, () => {
        resolve();
      });
    });
    return promise;
  }

  unAuthenticate() {
    const promise = new Promise((resolve, reject) => {
      // Clear global state
      localStorage.setItem("auth", JSON.stringify(null));

      // Clear local state
      this.setAppState({
        authenticated: false,
        auth: null
      }, () => {
        resolve();
      });
    });
    return promise;
  }

  /**
   * auth
   */

  appendAuthorizationToken(config) {
    const state = this.getAppState();
    config.headers["Authorization"] = `Bearer ${state.auth.accessToken}`;
  }

  auth_header_interceptor(config) {
    var state = this.getAppState();
    if ( state.authenticated ) {
      this.appendAuthorizationToken(config);
      return config;
    } else {
      this.log("[auth_header_interceptor] Initiating anonymous authentication...");

      this.authCognitoAnonymousGet();

      const authSubscribers = new Promise(resolve => {
        this.authSubscribers.push(() => {
          this.log("[auth_header_interceptor] Resolving...");
          this.appendAuthorizationToken(config);
          resolve(config);
        });
      });
      return authSubscribers;
    }
  }

  auth_error_interceptor(error) {
    this.log("[auth_error_interceptor] Entry point: " + error);

    if ( !error.response ) {
      return Promise.reject(error);
    }

    // Extract message and make it available as top level property for convenience
    this.appendErrorMessage(error);

    const { config, response: { status } } = error;
    const originalRequest = config;

    if ( 401 === status ) {
      this.log("[auth_error_interceptor] Initiating refresh...");

      if ( !this.isRefreshing ) {
        this.log("[auth_error_interceptor] Initial refresh request observed");

        this.isRefreshing = true;
        this.auth_cognito_refresh_post().then(response => {
          this.log("[auth_error_interceptor] Notifying refresh subscribers...");

          this.isRefreshing = false;
          this.refreshSubscribers.map(callback => callback());
          this.refreshSubscribers = [];
        }, error => {
          this.log("[auth_error_interceptor] Cleaning up subscribers upon failure to refresh...");

          this.isRefreshing = false;
          this.refreshSubscribers = [];
        });
      }
      const requestSubscribers = new Promise(resolve => {
        this.refreshSubscribers.push(() => {
          resolve(this.axiosAuth(originalRequest));
        });
      });
      return requestSubscribers;
    }
    return Promise.reject(error);
  }

  auth_cognito_refresh_post() {
    let that = this;
    this.log("[auth_cognito_refresh_post] Refreshing access token...");

    return new Promise((resolve, reject) => {
      this.axios.post(`/${this.api_version}/auth/core/refresh`, {
        "refresh_token": this.getAppState().auth.refreshToken
      })
        .then( response => {
          this.log("[auth_cognito_refresh_post] Access token obtained");

          // Remember new token
          const accessToken = response.data["access_token"];
          const expiresAt = response.data["expires_at"];
          this.refreshToken(accessToken, expiresAt).then(() => {
            // Complete request
            resolve(response);
          });
        }, error => {
          this.log("[auth_cognito_refresh_post] Failed to refresh access token. Signing out...");

          // Sign out completely
          this.unAuthenticate().then(() => {
            // Complete request with failure
            reject(error);
          });
        })
        .catch(function (error) {
          that.log("[auth_cognito_refresh_post] Failed to refresh token with generic error: " + error);
        });
    });
  }

  authCognitoAnonymousGet() {
    if ( this.isAuthenticating ) {
      this.log("[authCognitoAnonymousGet] Authentication is already in progress");
      return;
    }

    this.log("[authCognitoAnonymousGet] Initial auth request observed");

    this.isAuthenticating = true;
    this.authCognitoAnonymousGetCore().then(response => {
      this.log("[authCognitoAnonymousGet] Notifying auth subscribers...");

      this.isAuthenticating = false;
      this.authSubscribers.map(callback => callback());
      this.authSubscribers = [];
    }, error => {
      this.log("[authCognitoAnonymousGet] Cleaning up subscribers upon failure to auth...");

      this.isAuthenticating = false;
      this.authSubscribers = [];
    });
  }

  authCognitoAnonymousGetCore() {
    this.log("[authCognitoAnonymousGetCore] Fetching anonymous token...");

    return new Promise((resolve, reject) => {
      this.axios.get(`/${this.api_version}/auth/core/anonymous`)
        .then( response => {
          this.log("[authCognitoAnonymousGetCore] Anonymous token acquired");

          // Remember the token
          const refreshToken = response.data["refresh_token"];
          const accessToken = response.data["access_token"];
          const expiresAt = response.data["expires_at"];
          this.authenticate(true, refreshToken, accessToken, expiresAt).then(() => {
            // Complete request
            resolve(response);
          });
        }, error => {
          this.log("[authCognitoAnonymousGetCore] Failed to fetch anonymous token");

          // Sign out completely
          this.unAuthenticate();

          // Complete request with failure
          reject(error);
        })
        .catch(function (error) {
          this.log("[authCognitoAnonymousGetCore] Failed to fetch anonymous token with generic error: " + error);
        });
    });
  }

  authGoogleSigninPost(accessToken, externalId, name, email) {
    return new Promise((resolve, reject) => {
      this.log("[authGoogleSigninPost] Initiating token exchange...");

      const auth = {
        access_token: accessToken,
        email: email,
        name: name,
        external_id: externalId
      };
      this.axios.post(`/${this.api_version}/auth/google/signin`, auth)
        .then( response => {
          this.log("[authGoogleSigninPost] Token acquired");

          // Remember the token
          const refreshToken = response.data["refresh_token"];
          const accessToken = response.data["access_token"];
          const expiresAt = response.data["expires_at"];
          this.authenticate(false, refreshToken, accessToken, expiresAt);

          // Complete request
          resolve(response);
        }, error => {
          this.log("[authGoogleSigninPost] Failed to exchange token");
          reject(error);
        });
    });
  }

  /**
   * helpers
   */

  appendErrorMessage(error) {
    let message = "Unexpected error occurred";
    if ( error && error.response && error.response.data && error.response.data.message ) {
      message = error.response.data.message;
    }
    error.message = message;
  }

  /**
   * users
   */

  usersIdGet(userId) {
    return new Promise((resolve, reject) => {
      const cachedUser = this.usersCacheById[userId];
      if (cachedUser) {
        resolve(cachedUser);
        return;
      }

      this.axiosRefresh.get(`/${this.api_version}/users/${userId}`)
        .then( response => {
          this.usersCacheById[userId] = response;
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  usersIdGetNoCache(userId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/users/${userId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  usersIdPatch(userId, user) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.patch(`/${this.api_version}/users/${userId}`, user)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  users_id_orgs_get(userId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/users/${userId}/orgs`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  usersIdLimitsGet(userId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/users/${userId}/limits`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  usersIdCredentialsGet(userId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/users/${userId}/credentials`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  usersIdCredentialsPost(userId, credential) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/users/${userId}/credentials`, credential)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  usersCredentialsIdDelete(credentialId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.delete(`/${this.api_version}/users/credentials/${credentialId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  /**
   * orgs
   */

  orgsPost(org) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/orgs`, org)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  orgsIdGet(orgId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/orgs/${orgId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  orgsIdPatch(orgId, org) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.patch(`/${this.api_version}/orgs/${orgId}`, org)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  orgs_id_blobs_get(orgId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/orgs/${orgId}/blobs`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobsPost(orgId, blob) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/orgs/${orgId}/blobs`, blob)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  orgsIdLimitsGet(orgId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/orgs/${orgId}/limits`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  orgsIdDelete(orgId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.delete(`/${this.api_version}/orgs/${orgId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  /**
   * Blobs
   */

  blobsIdGet(orgId, blobId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/blobs/${orgId}/${blobId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobsIdPatch(orgId, blobId, blob) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.patch(`/${this.api_version}/blobs/${orgId}/${blobId}`, blob)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobsIdLimitsGet(orgId, blobId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/blobs/${orgId}/${blobId}/limits`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobsIdRevisionsGet(orgId, blobId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/blobs/${orgId}/${blobId}/revisions`)
        .then( response => {
          // Sort revisions by creation time in descending order
          response.data.revisions = response.data.revisions.sort((r1, r2) => r1.created_at > r2.created_at ? -1 : 1);

          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobsIdRevisionsPost(orgId, blobId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/blobs/${orgId}/${blobId}/revisions`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobsIdDelete(orgId, blobId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.delete(`/${this.api_version}/blobs/${orgId}/${blobId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  isBlobSettingsAccessible(blob) {
    return "admin" == blob.role || "owner" == blob.role;
  }

  hasBlobReachedTerminalSuccessStatus(blob) {
    return "ready" == blob.status;
  }

  isBlobInUpdatingStatus(blob) {
    return "updating" == blob.status;
  }

  isBlobInDeletingStatus(blob) {
    return "deleting" == blob.status;
  }

  hasBlobReachedTerminalFailureStatus(blob) {
    return "failed" == blob.status;
  }

  /**
   * Blob Metadata
   */

  blobsIdMetadataAliasGet(orgId, blobId, alias) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/blobs/${orgId}/${blobId}/metadata/${alias}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobsMetadataIdGet(itemId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/blobs/metadata/${itemId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  /**
   * Blob Members
   */

  blobsIdMembersPost(orgId, blobId, member) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/blobs/${orgId}/${blobId}/members`, member)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }


  blobsIdMembersGet(orgId, blobId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/blobs/${orgId}/${blobId}/members`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobMembersIdDelete(memberId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.delete(`/${this.api_version}/blobs/members/${memberId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  /**
   * Blob API Keys
   */

  blobsIdApiKeysGet(orgId, blobId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/blobs/${orgId}/${blobId}/api-keys`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobApiKeysIdDelete(apiKeyId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.delete(`/${this.api_version}/blobs/api-keys/${apiKeyId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobsIdApiKeysPost(orgId, blobId, apiKey) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/blobs/${orgId}/${blobId}/api-keys`, apiKey)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobWriteRoles = new Set(["owner", "admin", "write"]);

  haveBlobWriteAccess(blob) {
    return this.blobWriteRoles.has(blob.role);
  }

  /**
   * Revisions
   */

  revisionsIdGet(revisionId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/revisions/${revisionId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsIdDataCommandPost(revisionId, body, data) {
    let headers = {}
    if ( data ) {
      headers = {
        "Content-Type": "application/octet-stream",
        "X-Request-Body": JSON.stringify(body)
      };
    } else {
      headers = {
        "Content-Type": "application/json"
      };
      data = body;
    }
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/revisions/${revisionId}/data/command`, data, {
        headers: headers
      })
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsIdDataQueryPost(revisionId, body) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/revisions/${revisionId}/data/query`, body, {
        headers: {
          "Accept": "application/octet-stream"
        },
        responseType: "arraybuffer"
      })
        .then( response => {
          if ( "application/octet-stream" == response.headers["content-type"] ) {
            response.binary = response.data;
            response.data = JSON.parse(response.headers["x-response-body"]);
          } else if ( "application/json" == response.headers["content-type"] ) {
            const decoder = new TextDecoder("utf-8");
            const decoded = decoder.decode(response.data);
            response.data = JSON.parse(decoded);
          }
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsIdCommitPost(revisionId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/revisions/${revisionId}/commit`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsIdPatch(revisionId, revision) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.patch(`/${this.api_version}/revisions/${revisionId}`, revision)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsIdDelete(revisionId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.delete(`/${this.api_version}/revisions/${revisionId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  canUpdateRevision(blob, revision) {
    return ( this.haveBlobWriteAccess(blob) && "draft" == revision.phase );
  }

  canCommitRevision(blob, revision) {
    return ( this.haveBlobWriteAccess(blob) && "draft" == revision.phase && "ready" == revision.status );
  }

  canCreateRevision(blob, revision) {
    return (
      this.haveBlobWriteAccess(blob) &&
      revision.id == blob.latest_revision_id &&
      "commit" == revision.phase &&
      "ready" == revision.status
    );
  }

  canDeleteRevision(blob, revision) {
    return ( this.haveBlobWriteAccess(blob) && blob.latest_revision_id != revision.id && "ready" == revision.status );
  }

  hasRevisionReachedTerminalStatus(revision) {
    return ("ready" == revision.status || "failed" == revision.status);
  }

  /**
   * Revision Metadata
   */

  revisionsIdMetadataAliasGet(revisionId, alias) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/revisions/${revisionId}/metadata/${alias}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsIdMetadataAliasPost(revisionId, alias, item) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/revisions/${revisionId}/metadata/${alias}`, item)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsMetadataIdPut(itemId, item) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.put(`/${this.api_version}/revisions/metadata/${itemId}`, item)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsMetadataIdDelete(itemId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.delete(`/${this.api_version}/revisions/metadata/${itemId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  /**
   * Operations
   */

  operationsIdGet(operationId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/operations/${operationId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  isOperationStillInProgress(operation) {
    return "in_progress" == operation.status;
  }

  hasOperationCompleted(operation) {
    return "completed" == operation.status;
  }

  hasOperationFailed(operation) {
    return "failed" == operation.status;
  }

  /**
   * Workflow
   */

  workflowManifestGet(category) {
    // We use system blob/revision to load manifest from.
    // This should probably be promoted to a global method to eliminate this artificial confirmation step.
    const revisionId = Config.workflowManifestRevisionId;
    const body = {
      engine: "workflow_blobhub",
      command: "get_manifest",
      category: category
    };
    return this.revisionsIdDataQueryPost(revisionId, body);
  }

  /**
   * Workflow Execution Events
   */

   hasWorkflowExecutionReachedTerminalStatus(execution) {
      return "completed" == execution.status || "failed" == execution.status;
   }
}
