import React, { Component } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { GoX } from "react-icons/go";
import Components from "../common/components";
import "./index.css";

export default class View extends Component {
  constructor(props) {
    super(props);
    this.components = new Components();
  }

  onDragStart = (event, componentDefinition) => {
    if ( !this.props.shell.shellInterface.canEditRevision() ) {
      event.preventDefault();
      return;
    }

    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/plain", JSON.stringify(componentDefinition));
  }

  render() {
    if ( !this.props.shell.manifest ) {
      return ( <></> );
    }

    return (
      <Card className="workflow-library-pane">
        <Card.Body className="workflow-library-components">
          <span className="workflow-controls-pane-right">
            <Button variant="light" onClick={() => {
              this.props.shell.shellInterface.updatePane("library", false);
            }}><GoX/></Button>
          </span>

          {this.props.shell.manifest["component_groups"].map((componentGroup, index) => {
            return (
              <>
                <Card.Text as="h6">{componentGroup.label}</Card.Text>
                <Table hover bordered className="workflow-library-table">
                  <tbody>
                    {componentGroup["component_manifests"].map((componentDefinition, index) => {
                      return (
                        <tr>
                          <td className="align-middle workflow-library-icon">
                            {this.components.getComponentIcon(componentDefinition.type)}
                          </td>
                          <td onDragStart={(event) => this.onDragStart(event, componentDefinition)}
                            className="workflow-library-processor" draggable>
                            <code>{componentDefinition.type}</code> <br/>
                            <strong>{componentDefinition.label}</strong> <br/>
                            {componentDefinition.description}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </>
            );
          })}
        </Card.Body>
      </Card>
    );
  }
}
