import React, { Component } from 'react';
import { Container, Table, Button, Row, Badge, Alert, Modal, Form, Col } from 'react-bootstrap';
import { GoCode, GoPerson, GoGitCommit, GoLock, GoTrash, GoPencil } from 'react-icons/go';
import { FiCommand } from 'react-icons/fi';
import Utils from "context/utils";

class EditRevisionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      revision: {
        comment: ""
      }
    };

    this.form = React.createRef();
  }

  onEnter = () => {
    this.setState({
      revision: {
        comment: this.props.revision.comment
      }
    });
  }

  handleEdit = () => {
    // Check form validity
    if ( !this.form.current.reportValidity() ) {
      return;
    }

    // Initiate processing
    const revision = {
      comment: this.state.revision.comment
    };

    this.props.handleEdit(revision);

    // Close modal
    this.props.onHide();
  }

  render() {
    return (
      <Modal
        onEnter={this.onEnter}
        onHide={this.props.onHide}
        show={this.props.show}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Revision
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={this.form}>
            <Form.Group>
              <Form.Label>
                <strong>Comment</strong>
              </Form.Label>
              <Row>
                <Col sm={12}>
                  <Form.Control
                    type="text"
                    size="sm"
                    autoFocus
                    value={this.state.revision.comment}
                    onChange={(event) => {
                      let revision = this.state.revision;
                      revision.comment = event.target.value;
                      this.setState({revision: revision});
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                Provide comment describing the revision.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.onHide}>Close</Button>
          <Button variant="success" onClick={this.handleEdit}>Apply</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class RevisionView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      author: null,
      editing: false,
      committing: false,
      creating: false,
      deleting: false,
      hasFailed: false,
      errorMessage: null,
      editModalShow: false,
      setEditModalShow: (show) => {
        this.setState({editModalShow: show});
      }
    }
  }

  componentDidMount() {
    this.loadAuthor();
  }

  formatRelativeRevisionUrl(revision) {
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;
    const path = `\/${orgId}\/${blobId}\/revisions\/${revision.id}`;
    return path;
  }

  loadAuthor() {
    // Load author
    this.props.app.api.usersIdGet(this.props.revision.author_id)
      .then(response => {
        this.setState({
          author: response.data.user
        });
      }, error => {});
  }

  handleEditInit() {
    this.setState({
      hasFailed: false,
      errorMessage: null,
      editModalShow: true
    });
  }

  handleEdit = (revision) => {
    this.setState({
      editing: true,
      hasFailed: false,
      errorMessage: null
    });

    // Editing revision
    this.props.app.api.revisionsIdPatch(this.props.revision.id, revision)
      .then(response => {
        this.setState({
          editing: false,
          hasFailed: false,
          errorMessage: null
        });

        // Refresh revisions
        this.props.revisionsInterface.refresh(true);
      }, error => {
        // Indicate error
        this.setState({
          editing: false,
          hasFailed: true,
          errorMessage: error.message
        });
      });
  }

  handleCommit() {
    this.setState({
      committing: true,
      hasFailed: false,
      errorMessage: null
    });

    // Commit revision
    this.props.app.api.revisionsIdCommitPost(this.props.revision.id)
      .then(response => {
        this.setState({
          committing: false,
          hasFailed: false,
          errorMessage: null
        });

        // Refresh revisions
        this.props.revisionsInterface.refresh(true);
      }, error => {
        // Indicate error
        this.setState({
          committing: false,
          hasFailed: true,
          errorMessage: error.message
        });
      });
  }

  handleNew() {
    this.setState({
      creating: true,
      hasFailed: false,
      errorMessage: null
    });

    // Commit revision
    const orgId = this.props.blob.org_id;
    const blobId = this.props.blob.id;
    this.props.app.api.blobsIdRevisionsPost(orgId, blobId)
      .then(response => {
        this.setState({
          creating: false,
          hasFailed: false,
          errorMessage: null
        });

        // Refresh revisions
        this.props.revisionsInterface.refresh(true);
      }, error => {
        // Indicate error
        this.setState({
          creating: false,
          hasFailed: true,
          errorMessage: error.message
        });
      });
  }

  handleDelete() {
    this.setState({
      deleting: true,
      hasFailed: false,
      errorMessage: null
    });

    // Delete revision
    const orgId = this.props.blob.org_id;
    const blobId = this.props.blob.id;
    this.props.app.api.revisionsIdDelete(this.props.revision.id)
      .then(response => {
        this.setState({
          deleting: false,
          hasFailed: false,
          errorMessage: null
        });

        // Refresh revisions
        this.props.revisionsInterface.refresh(true);
      }, error => {
        // Indicate error
        this.setState({
          deleting: false,
          hasFailed: true,
          errorMessage: error.message
        });
      });
  }

  render() {
    return (
      <>
        <tr>
          <td>
            <div class="d-flex">
              <div>
                <strong>{this.props.revision.comment}</strong>&nbsp;&nbsp;
                <Badge variant="secondary" className="title-tag-badge">{this.props.revision.phase}</Badge>
                {this.props.revision.id == this.props.blob.latest_revision_id &&
                  <>
                    &nbsp;&nbsp;
                    <Badge variant="secondary" className="title-tag-badge">latest</Badge>
                  </>
                }
                {(this.props.blobRevision && this.props.revision.id == this.props.blobRevision.id) &&
                  <>
                    &nbsp;&nbsp;
                    <Badge variant="secondary" className="title-tag-badge">selected</Badge>
                  </>
                }
                <br/>
                ID: <code>{this.props.revision.id}</code> |
                Status: <code>{this.props.revision.status}</code> <br/>

                {this.props.blob &&
                  <>
                    Domain: <code>{this.props.blob.domain}</code> |
                    Type: <code>{this.props.blob.type}</code> |
                    Format: <code>{this.props.blob.format}</code> <br/>
                  </>
                }

                {this.props.revision.interpreters &&
                  <>
                    {this.props.revision.interpreters.map((interpreter, index) => {
                      return (
                        <>
                          <FiCommand/> <code>{interpreter.name} ({interpreter.version})</code> interpreter<br/>
                        </>
                      );
                    })}
                  </>
                }

                {this.state.author &&
                  <>
                    <GoPerson/>
                    <strong>
                      &nbsp;<a href={`/p/${this.state.author.alias}`}>{this.state.author.alias}</a>
                    </strong>
                    &nbsp;created {Utils.formatTimeAgo(this.props.revision.created_at)} <br/>
                  </>
                }
              </div>

              <div class="ml-auto">
                {(this.props.revisionsInterface.allRevisionsInTerminalStatus &&
                  this.props.app.api.canCommitRevision(this.props.blob, this.props.revision)) &&
                  <>
                    <Button
                      variant="success"
                      className="btn-icon"
                      onClick={() => this.handleEditInit()}
                      ><GoPencil /> <span>Edit</span></Button>
                    &nbsp;&nbsp;
                    <Button
                      variant="success"
                      className="btn-icon"
                      onClick={() => this.handleCommit()}
                      ><GoLock /> <span>Commit</span></Button>
                    &nbsp;&nbsp;
                  </>
                }

                {(this.props.revisionsInterface.allRevisionsInTerminalStatus &&
                  this.props.app.api.canCreateRevision(this.props.blob, this.props.revision)) &&
                  <>
                    <Button
                      variant="success"
                      className="btn-icon"
                      onClick={() => this.handleNew()}
                      ><GoGitCommit /> <span>New</span></Button>
                    &nbsp;&nbsp;
                  </>
                }

                <Button variant="light" className="btn-icon"><GoCode /></Button>

                {(this.props.revisionsInterface.allRevisionsInTerminalStatus &&
                  this.props.app.api.canDeleteRevision(this.props.blob, this.props.revision)) &&
                  <>
                    &nbsp;&nbsp;
                    <Button
                      variant="danger"
                      className="btn-icon"
                      onClick={() => this.handleDelete()}
                      ><GoTrash /></Button>
                  </>
                }
              </div>
            </div>
          </td>
        </tr>
        {this.state.editing &&
          <tr>
            <td style={{padding: "0px"}}>
              <div>
                <Alert variant="primary">
                  Updating revision details...
                </Alert>
              </div>
            </td>
          </tr>
        }
        {this.state.committing &&
          <tr>
            <td style={{padding: "0px"}}>
              <div>
                <Alert variant="primary">
                  Initiating revision commit...
                </Alert>
              </div>
            </td>
          </tr>
        }
        {this.state.creating &&
          <tr>
            <td style={{padding: "0px"}}>
              <div>
                <Alert variant="primary">
                  Initiating revision creation...
                </Alert>
              </div>
            </td>
          </tr>
        }
        {this.state.deleting &&
          <tr>
            <td style={{padding: "0px"}}>
              <div>
                <Alert variant="primary">
                  Initiating revision deletion...
                </Alert>
              </div>
            </td>
          </tr>
        }
        {this.state.hasFailed &&
          <tr>
            <td style={{padding: "0px"}}>
              <div>
                <Alert variant="warning">
                  {this.state.errorMessage}
                </Alert>
              </div>
            </td>
          </tr>
        }

        <EditRevisionModal
          show={this.state.editModalShow}
          onHide={() => this.state.setEditModalShow(false)}
          handleEdit={this.handleEdit}
          revision={this.props.revision}
        />
      </>
    );
  }
}

export default class BlobRevisions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: this.refresh,
      revisions: null,
      allRevisionsInTerminalStatus: true
    };
  }

  componentDidMount() {
    this.refresh(false);
  }

  refresh = (forcedBlobRefresh) => {
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;

    // Load revisions
    this.props.app.api.blobsIdRevisionsGet(orgId, blobId)
      .then(response => {
        const revisions = response.data.revisions;

        // Look for revisions that are being transitioned into a different state
        let allRevisionsInTerminalStatus = true;
        for ( let revision of revisions ) {
          if ( !this.props.app.api.hasRevisionReachedTerminalStatus(revision) ) {
            allRevisionsInTerminalStatus = false;
            break;
          }
        }

        // Schedule refresh if there are in-flight revisions
        if ( !allRevisionsInTerminalStatus ) {
          setTimeout(() => {
            this.refresh(forcedBlobRefresh);
          }, 3000);
        }

        // Remember data
        const prevAllRevisionsInTerminalStatus = this.state.allRevisionsInTerminalStatus;
        this.setState({
          revisions: revisions,
          allRevisionsInTerminalStatus: allRevisionsInTerminalStatus
        });

        // Refresh latest blob revision when transitioning back to steady state
        if ( forcedBlobRefresh || (allRevisionsInTerminalStatus && !prevAllRevisionsInTerminalStatus) ) {
          this.props.blobInterface.loadBlob();
        }
      }, error => {
        // Remember data
        this.setState({
          revisions: null
        });
      });
  }

  render() {
    if ( null == this.state.revisions ) {
      return (
        <>
          <Container className="blob-header-row content-row">
            Loading revisions...
          </Container>
        </>
      );
    }

    return (
      <>
        <Container className="blob-header-row content-row">
          {this.state.revisions && 0 != this.state.revisions.length &&
            <Table hover bordered>
              <tbody>
                {this.state.revisions.map((revision, index) => {
                  return (
                    <RevisionView
                      app={this.props.app}
                      blob={this.props.blob}
                      blobRevision={this.props.revision}
                      match={this.props.match}
                      revisionsInterface={this.state}
                      revision={revision}
                    />
                  );
                })}
              </tbody>
            </Table>
          }
          {this.state.revisions && 0 == this.state.revisions.length &&
            <>
              No revisions found for the blob
            </>
          }
        </Container>
      </>
    );
  }
}
