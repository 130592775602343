import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Container, Navbar, Nav, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { GoScreenNormal, GoScreenFull } from 'react-icons/go';
import Footer from 'components/controls/Footer/index';
import Main from '../Main/index';
import Profile from '../Profile/index';
import OrgCreate from '../Org/create';
import Org from '../Org/index';
import Blob from '../Blob/index';
import BlobCreate from '../Blob/create';
import BlobRevisions from '../Blob/revisions';
import Auth from '../Auth/callback';
import UserMenu from '../Auth/user';
import Api from 'context/api';
import { Terms, Cookies, Privacy, Beta } from "pages/Main/legal";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screenMode: "normal",
      authenticated: false,
      auth: null,
      api: null
    };

    this.state.api = new Api(
      this.state,
      (state) => {
        const promise = new Promise((resolve, reject) => {
          this.setState(state, () => {
            resolve();
          });
        });
        return promise;
      },
      () => { return this.state });
  }

  handleScreenModeChange = (value, event) => {
    this.setState({
      screenMode: value
    });
  }

  render() {
    const PrivateRoutes = (props) => {
      return ( props.app.authenticated ? <Outlet/> : <Navigate to='/'/> )
    }

    const RouteWrapper = ({component: Component, ...rest}) => {
      const params = useParams();
      const location = useLocation();
      const navigate = useNavigate();
      return <Component
        app={this.state}
        {
          ...{
            ...rest,
            match: {
              params: params,
              path: location.pathname
            },
            history: {
              push: path => {
                navigate(path);
              }
            }
          }
        }
      />
    }

    return (
      <Router>
        <Navbar bg="dark" variant="dark" expand="lg" className="shadow-sm">
          <Container>
            <Navbar.Brand href="/">BlobHub</Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link href="/p/blobhub">Featured</Nav.Link>
            </Nav>
            <Nav>
              <UserMenu
                app={this.state}
              />
            </Nav>
          </Container>
        </Navbar>

        <Routes>
          <Route path="/" element={<Main/>} />

          <Route path="/terms" element={<Terms/>} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/cookies" element={<Cookies/>} />
          <Route path="/beta" element={<Beta/>} />

          <Route path="/auth/:provider/callback"
            render={(props) => <Auth {...props} app={this.state} />}
          />

          <Route element={<PrivateRoutes app={this.state}/>}>
            <Route path="/p/:userId/org/create" element={<RouteWrapper component={OrgCreate}/>} />

            <Route path="/p/:userId" element={<RouteWrapper component={Profile}/>} />
            <Route path="/p/:userId/:section" element={<RouteWrapper component={Profile}/>} />
            <Route path="/p/:userId/:section/:sectionId" element={<RouteWrapper component={Profile}/>} />

            <Route path="/:orgId/settings" element={<RouteWrapper component={Org}/>} />
            <Route path="/:orgId/settings/:sectionId" element={<RouteWrapper component={Org}/>} />

            <Route path="/:orgId/blob/create" element={<RouteWrapper component={BlobCreate}/>} />
          </Route>

          <Route path="/:orgId" element={<RouteWrapper component={Org}/>} />

          <Route path="/:orgId/:blobId" element={<RouteWrapper component={Blob}/>} />
          <Route path="/:orgId/:blobId/:section" element={<RouteWrapper component={Blob}/>} />
          <Route path="/:orgId/:blobId/:section/:sectionId" element={<RouteWrapper component={Blob}/>} />
          <Route path="/:orgId/:blobId/:section/:sectionId/:subSectionId" element={<RouteWrapper component={Blob}/>} />
        </Routes>

        <Footer/>
      </Router>
    );
  }
}
